import { defineNuxtPlugin } from '#app/nuxt'
import { LazySvgoAbcLogo, LazySvgoCarOffers, LazySvgoCargurusLogo, LazySvgoCarmaxLogo, LazySvgoCbsLogo, LazySvgoFoxLogo, LazySvgoKbbicoLogo, LazySvgoKeyHandoff, LazySvgoListcarforsaleLogo, LazySvgoLutherbuyscarsLogo, LazySvgoNbcLogo, LazySvgoOfferComparison } from '#components'
const lazyGlobalComponents = [
  ["SvgoAbcLogo", LazySvgoAbcLogo],
["SvgoCarOffers", LazySvgoCarOffers],
["SvgoCargurusLogo", LazySvgoCargurusLogo],
["SvgoCarmaxLogo", LazySvgoCarmaxLogo],
["SvgoCbsLogo", LazySvgoCbsLogo],
["SvgoFoxLogo", LazySvgoFoxLogo],
["SvgoKbbicoLogo", LazySvgoKbbicoLogo],
["SvgoKeyHandoff", LazySvgoKeyHandoff],
["SvgoListcarforsaleLogo", LazySvgoListcarforsaleLogo],
["SvgoLutherbuyscarsLogo", LazySvgoLutherbuyscarsLogo],
["SvgoNbcLogo", LazySvgoNbcLogo],
["SvgoOfferComparison", LazySvgoOfferComparison],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
