import { defineNuxtPlugin } from "#app";
import { createVuetify, ThemeDefinition } from "vuetify";
import { aliases, mdi } from "vuetify/iconsets/mdi-svg";
import "vuetify/styles";
import "vuetify/lib/styles/main.sass";
import { md3 } from "vuetify/blueprints";
import { VCard, VBtn } from "vuetify/components";

const lightTheme: ThemeDefinition = {
  dark: false,
  colors: {
    /* primary */
    primary0: "#000000",
    primary5: "#000C38",
    primary10: "#001550",
    primary20: "#002780",
    primary25: "#003099",
    primary30: "#003AB3",
    primary35: "#0044CD",
    primary40: "#004EE8",
    primary50: "#396AFF",
    primary60: "#6889FF",
    primary70: "#90A7FF",
    primary80: "#B6C4FF",
    primary90: "#DCE1FF",
    primary95: "#EFF0FF",
    primary98: "#FAF8FF",
    primary99: "#FEFBFF",
    primary100: "#FFFFFF",
    /* secondary */
    secondary0: "#000000",
    secondary5: "#000C37",
    secondary10: "#00164F",
    secondary20: "#082978",
    secondary25: "#1A3584",
    secondary30: "#284190",
    secondary35: "#354D9C",
    secondary40: "#4259A9",
    secondary50: "#5C73C4",
    secondary60: "#768DE0",
    secondary70: "#90A7FD",
    secondary80: "#B6C4FF",
    secondary90: "#DCE1FF",
    secondary95: "#EFF0FF",
    secondary98: "#FAF8FF",
    secondary99: "#FEFBFF",
    secondary100: "#FFFFFF",
    /* tertiary */
    tertiary0: "#000000",
    tertiary5: "#20071E",
    tertiary10: "#2C122A",
    tertiary20: "#43273F",
    tertiary25: "#4F324B",
    tertiary30: "#5B3D57",
    tertiary35: "#684863",
    tertiary40: "#75546F",
    tertiary50: "#8F6C89",
    tertiary60: "#AA85A3",
    tertiary70: "#C79FBE",
    tertiary80: "#E3BADA",
    tertiary90: "#FFD7F5",
    tertiary95: "#FFEBF8",
    tertiary98: "#FFF7F9",
    tertiary99: "#FFFBFF",
    tertiary100: "#FFFFFF",
    /* error */
    error0: "#000000",
    error5: "#2D0001",
    error10: "#410002",
    error20: "#690005",
    error25: "#7E0007",
    error30: "#93000A",
    error35: "#A80710",
    error40: "#BA1A1A",
    error50: "#DE3730",
    error60: "#FF5449",
    error70: "#FF897D",
    error80: "#FFB4AB",
    error90: "#FFDAD6",
    error95: "#FFEDEA",
    error98: "#FFF8F7",
    error99: "#FFFBFF",
    error100: "#FFFFFF",
    /* neutral */
    neutral0: "#000000",
    neutral5: "#101114",
    neutral10: "#1B1B1F",
    neutral20: "#303034",
    neutral25: "#3B3B3F",
    neutral30: "#47464A",
    neutral35: "#525256",
    neutral40: "#5E5E62",
    neutral50: "#77767A",
    neutral60: "#919094",
    neutral70: "#ACAAAF",
    neutral80: "#C8C6CA",
    neutral90: "#E4E1E6",
    neutral95: "#F2F0F4",
    neutral98: "#FBF8FD",
    neutral99: "#FEFBFF",
    neutral100: "#FFFFFF",
    /* neutralVariant */
    neutralVariant0: "#000000",
    neutralVariant5: "#0F1118",
    neutralVariant10: "#1A1B23",
    neutralVariant20: "#2F3038",
    neutralVariant25: "#3A3B43",
    neutralVariant30: "#45464F",
    neutralVariant35: "#51525B",
    neutralVariant40: "#5D5E67",
    neutralVariant50: "#767680",
    neutralVariant60: "#90909A",
    neutralVariant70: "#AAAAB4",
    neutralVariant80: "#C6C5D0",
    neutralVariant90: "#E2E1EC",
    neutralVariant95: "#F0F0FA",
    neutralVariant98: "#FAF8FF",
    neutralVariant99: "#FEFBFF",
    neutralVariant100: "#FFFFFF",
    /* light */
    primaryLight: "#004EE8",
    onPrimaryLight: "#FFFFFF",
    primaryContainerLight: "#DCE1FF",
    onPrimaryContainerLight: "#001550",
    primaryFixedLight: "#DCE1FF",
    onPrimaryFixedLight: "#001550",
    primaryFixedDimLight: "#B6C4FF",
    onPrimaryFixedVariantLight: "#003AB3",
    secondaryLight: "#4259A9",
    onSecondaryLight: "#FFFFFF",
    secondaryContainerLight: "#DCE1FF",
    onSecondaryContainerLight: "#00164F",
    secondaryFixedLight: "#DCE1FF",
    onSecondaryFixedLight: "#00164F",
    secondaryFixedDimLight: "#B6C4FF",
    onSecondaryFixedVariantLight: "#284190",
    tertiaryLight: "#75546F",
    onTertiaryLight: "#FFFFFF",
    tertiaryContainerLight: "#FFD7F5",
    onTertiaryContainerLight: "#2C122A",
    tertiaryFixedLight: "#FFD7F5",
    onTertiaryFixedLight: "#2C122A",
    tertiaryFixedDimLight: "#E3BADA",
    onTertiaryFixedVariantLight: "#5B3D57",
    errorLight: "#BA1A1A",
    onErrorLight: "#FFFFFF",
    errorContainerLight: "#FFDAD6",
    onErrorContainerLight: "#410002",
    outlineLight: "#767680",
    backgroundLight: "#FEFBFF",
    onBackgroundLight: "#1B1B1F",
    surfaceLight: "#FBF8FD",
    onSurfaceLight: "#1B1B1F",
    surfaceVariantLight: "#E2E1EC",
    onSurfaceVariantLight: "#45464F",
    inverseSurfaceLight: "#303034",
    inverseOnSurfaceLight: "#F2F0F4",
    inversePrimaryLight: "#B6C4FF",
    shadowLight: "#000000",
    surfaceTintLight: "#004EE8",
    outlineVariantLight: "#C6C5D0",
    scrimLight: "#000000",
    surfaceContainerHighestLight: "#E4E1E6",
    surfaceContainerHighLight: "#EAE7EC",
    surfaceContainerLight: "#EFEDF1",
    surfaceContainerLowLight: "#F5F3F7",
    surfaceContainerLowestLight: "#FFFFFF",
    surfaceBrightLight: "#FBF8FD",
    surfaceDimLight: "#DBD9DE",
  },
};

const vuetifyAppDefaults = {
  theme: {
    defaultTheme: "lightTheme",
    themes: {
      lightTheme,
    },
  },
  aliases: {
    VWorkflowCard: VCard,
    VStepCard: VCard,
    VNavBtn: VBtn,
    VNavBackBtn: VBtn,
  },
  defaults: {
    VWorkflowCard: {
      class: "rounded-xl bg-surfaceLight text-onSurfaceLight",
      elevation: 0,
      VCardTitle: {
        class: "text-subtitle-1 text-center px-4",
        style: { whiteSpace: "normal", wordBreak: "break-word", lineHeight: "1.5" },
      },
      VCardSubtitle: {
        class: "text-center",
        style: { whiteSpace: "normal", wordBreak: "break-word", lineHeight: "1.5" },
      },
    },
    VTextField: {
      class: "mb-2",
      variant: "outlined",
      rounded: "md",
      color: "neutralVariant50",
      // bgColor: "white",
    },
    VSelect: {
      class: "mb-2",
      variant: "outlined",
      rounded: "md",
      color: "neutralVariant50",
      // bgColor: "white",
    },
    VAutocomplete: {
      class: "mb-2",
      variant: "outlined",
      rounded: "md",
      color: "neutralVariant50",
      // bgColor: "white",
    },
    VNavBtn: {
      class: "my-2 py-5 rounded-pill h-auto w-100 text-capitalize text-onPrimaryLight",
      minWidth: "auto",
      color: "primaryLight",
      size: "x-large",
      elevation: 0,
      style: { letterSpacing: "inherit" },
    },
    VBtn: {
      ripple: false,
    },
  },
};

export default defineNuxtPlugin((nuxtApp) => {
  const vuetify = createVuetify({
    blueprint: md3,
    ssr: true,
    icons: {
      defaultSet: "mdi",
      aliases,
      sets: {
        mdi,
      },
    },
    ...vuetifyAppDefaults,
  });

  nuxtApp.vueApp.use(vuetify);
});
