import { defineNuxtPlugin, useRouter, useRuntimeConfig } from "#app";
import * as Sentry from "@sentry/vue";

export default defineNuxtPlugin((nuxtApp) => {
  const config = useRuntimeConfig();
  const router = useRouter();
  const logger = useLogger("sentry-plugin");

  logger.info("Loading Sentry Plugin", config.public.sentry.dsn, config.public.sentry_env);

  Sentry.init({
    app: nuxtApp.vueApp,
    dsn: config.public.sentry.dsn,
    environment: config.public.sentry.env,
    trackComponents: true,
    // docs say you can put here but logs say it's not picked up??
    tracePropagationTargets: [
      "localhost",
      /^https:\/\/onlinecarvalues\.dealerapps\.io/,
      /^https:\/\/onlinecarvalues\.dealerappspro\.com/,
    ],
    tracesSampleRate: 1.0,
    replaysOnErrorSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,

    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracePropagationTargets: [
          "localhost",
          /^https:\/\/onlinecarvalues\.dealerapps\.io/,
          /^https:\/\/onlinecarvalues\.dealerappspro\.com/,
        ],
      }),
    ],
  });

  // only add this to prod as we use up credits
  if (config.public.sentry_env === "production") {
    Sentry.addIntegration(new Sentry.Replay());
  }

  return {
    provide: {
      sentry: Sentry,
    },
  };
});
