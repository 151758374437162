import { defineNuxtPlugin, useRouter } from "nuxt/app";
import { createGtm, type VueGtmUseOptions } from "@gtm-support/vue-gtm";

export default defineNuxtPlugin((nuxtApp) => {
  if (process.client) {
    const options = nuxtApp.$config.public.gtm;
    const router = useRouter();
    const logger = useLogger("gtm.client");

    const pluginOptions: VueGtmUseOptions = {
      ...options,
      vueRouter: options.enableRouterSync && router ? (router as VueGtmUseOptions["vueRouter"]) : undefined,
      trackViewEventProperty: "PageView",
    };

    nuxtApp.vueApp.use(createGtm(pluginOptions));
  }
});
